<template>
    <div>
        <section>
            <div class="columns vh">
                <div class="column is-6 is-offset-1 has-vertically-aligned-content">
                    <div class="p-4 container">
                        <h1 class="title xl is-size-1-mobile pr-6">Case Studies</h1>
                        <div class="subtitle">
                            Eduworks solutions use artificial intelligence (AI) to
                            improve processes that require reading and understanding text.
                            They act as performance multipliers that increase speed and accuracy,
                            decrease cost, and enable your team to make the best use of its time and expertise.
                        </div>
                    </div>
                </div>
                <div
                    class="column has-vertically-aligned-content case-study-img-holder m-0 hide-mobile"
                ></div>
            </div>
        </section>
        <hr />
        <section class="section is-medium container">
            <div class="columns is-multiline is-centered">
                <div
                    v-for="caseStudy in caseStudies"
                    :key="caseStudy.id"
                    class="column p-0 is-4 m-4 case-study-card has-shadow is-fullwidth-tablet"
                    :style="{ backgroundImage: `var(--case-study-overlay), url(${`https://cms.eduworks.com` + caseStudy.image.url})` }"
                    :alt="caseStudy.image.alternativeText"
                    div
                    :class="{'is-draft':caseStudy.published_at === null}"
                >
                    <router-link :to="{ path: 'case-studies/' + caseStudy.url }">
                        <div>
                            <div class="case-study-card-1 p-6 is-fullwidth-mobile is-half-desktop">
                                <div class="case-study-card-text">
                                    <h1 class="title is-1">{{ caseStudy.title }}</h1>
                                    <p class="hide-mobile">{{caseStudy.description}}</p>
                                    <div class="block mt-4">
                                        <div
                                            class="tag is-primary has-text-weight-bold mb-1 is-radiusless mr-3"
                                            v-for="caseStudyTag in caseStudy.tags"
                                            :key="caseStudyTag.id"
                                        >{{ caseStudyTag.tag }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="case-study-card-2">
                                <router-link :to="{ path: 'case-studies/' + caseStudy.url }">
                                    <h3
                                        class="title has-text-white has-background-primary is-3 p-5 case-study-button"
                                    >View Case Study</h3>
                                </router-link>
                            </div>
                        </div>
                        <div
                            class="hide-desktop has-background-primary has-text-white title is-4 p-4 has-text-centered has-text-weight-bold"
                        >
                            View Case Study
                            <i class="fas fa-chevron-right ml-2 fa-sm"></i>
                            <i class="fas fa-chevron-right fa-sm"></i>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'caseStudies',
    components: {},
    data: () => {
        return {}
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: 'Case Studies',
        // all titles will be injected into this template unless template is set to null
        titleTemplate: 'Eduworks | %s'
    },
    mounted() {
        if (this.$store.state.caseStudies.length < 1)
            this.$store.dispatch('getCaseStudies')
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl
        },
        caseStudies() {
            return this.$store.state.caseStudies
        }
    },
    methods: {
        notEmpty(data) {
            // check if optional strapi data item has a value
            // ex: v-if="notEmpty(job.howToApply)"
            if (!data) return false
            return true
        },
        getImgUrl(pic) {
            if (!pic) return
            return require('../assets/' + pic)
        }
    }
}
</script>